import styled from 'styled-components'
import { VideoGroupRecord } from 'types'
import { VideoCard } from './VideoCard'
import { responsiveImageBlock } from '@/graphql/utils/commonBlocks'
import { FragmentComponent } from '@/types/graphql'
import Carousel from '@/components/pages/homepage/content/carousel/Carousel'

const fragment = `
fragment VideoGroupFragment on VideoGroupRecord {
        __typename
        id
        videoGroupHeading
        asCarousel
        carouselTheme
        customHeight
        videoCards {
          blocks {
            title
            video {
              video {
                streamingUrl
                muxPlaybackId
                mp4Url
                muxAssetId
              }
            }
            placeholderImage {
              url
              ${responsiveImageBlock()}
            }
            textOverlay {
              value
            }
          }
        }
      }
`

const StyledContainer = styled.div<{ $asCarousel?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ $asCarousel }) => !$asCarousel && `margin: 72px auto;`};

  h2 {
    margin: 0 0 32px 0;
    font-size: ${(props) => props.theme.v2.typography.body.l.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.l.lineHeight};
    font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
    color: ${(props) => props.theme.v2.onSurface.subduedText};
  }
`
const StyledVideoGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 55px;
  align-items: center;

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`

export const VideoGroup: FragmentComponent<{}, VideoGroupRecord> = ({ record }) => {
  const { videoCards, videoGroupHeading } = record
  const videos = videoCards.blocks
  const content = videos.map((v) => {
    return (
      <VideoCard
        title={v.title}
        video={v.video?.video}
        placeholderImage={v.placeholderImage}
        key={v.title}
        customHeight={record.customHeight}
        textOverlay={v.textOverlay}
      />
    )
  })

  return (
    <StyledContainer $asCarousel={record.asCarousel}>
      {videoGroupHeading && <h2>{videoGroupHeading}</h2>}
      {record.asCarousel ? (
        <Carousel content={content} theme={record.carouselTheme} />
      ) : (
        <StyledVideoGroupContainer>{content}</StyledVideoGroupContainer>
      )}
    </StyledContainer>
  )
}

VideoGroup.fragment = fragment
VideoGroup.recordName = 'VideoGroupRecord'
