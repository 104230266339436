import Image from 'next/image'
import { trackVideoEvent } from '@/lib/gtm/gtmV2'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { ResponsiveImageType, StructuredTextGraphQlResponse } from 'react-datocms'
import styled from 'styled-components'
import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSStyledStructuredText } from '@/blocks/DesignSystem/DSStyledStructuredText'
import { Button } from 'react-aria-components'
import { DSIcon } from '@zoe/ds-web'

const StyledVideoContainer = styled.div<{ $height?: number | undefined; $videoStarted: boolean; $hasVideo: boolean }>`
  position: relative;
  flex: 1;
  height: ${({ $height }) => ($height ? `${$height}px` : '550px')};
  border-radius: 16px;
  margin: 1px;

  ${({ $hasVideo }) =>
    $hasVideo &&
    `
    &:hover {
      box-shadow: 0 0 0 1px var(--color-border-focused);
    }
  `}

  img {
    height: ${({ $height }) => ($height ? `${$height}px` : '550px')};
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 100%;
  }

  ${({ $videoStarted, $hasVideo }) => !$videoStarted && $hasVideo && `cursor: pointer; `};
`

const StyledVideoWrap = styled.div<{ $maxWidth?: number | undefined }>`
  overflow: hidden;
  position: relative;
  height: inherit;
  max-width: ${({ $maxWidth }) => ($maxWidth ? `${$maxWidth}px` : '350px')};

  min-width: 100%;
  min-height: 100%;
  border-radius: 16px;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyledTextOverlay = styled.div`
  position: absolute;
  bottom: 0;
  height: 70%;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: left;
  padding: var(--grid-24);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 100%);
  border-radius: 0 0 var(--grid-16) var(--grid-16);

  color: var(--color-surface-cream-subtle);

  & blockquote {
    margin: 0;
    position: absolute;
    bottom: var(--grid-24);
    left: var(--grid-24);
    right: var(--grid-24);

    & footer {
      font-weight: 600;
      bottom: var(--grid-24);
    }

    & p {
      font-size: var(--font-size-fluid-paragraph-400);
      line-height: var(--line-height-fluid-paragraph-400);
      font-weight: var(--font-weight-fluid-paragraph-400);
      margin-bottom: var(--grid-16);
    }
  }
`

const StyledPlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, calc(-50% + 10px));

  p {
    text-shadow: 1px 1px 4px rgb(0 0 0 / 60%);
    ${(p) => p.theme.v2.typography.label.l};
    font-family: ${(p) => p.theme.v2.font.lotaGrotesqueSemiBold};
    color: white;
    margin: 5px 0 0 -10px;
    text-align: center;
  }
`

const StyledPlayIcon = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  width: 60px;
  height: 60px;
  margin-bottom: 0;
  border-width: 2px;
  border: 3px solid #fff;
  border-radius: 45px;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    transform: translateX(2px);
  }
`

const AltStyledPlayButton = styled(Button)`
  position: absolute;
  top: 44%;
  left: 8%;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  background-color: var(--color-surface-cream-bold);
  z-index: 3;

  &:focus-visible {
    outline: 0;
  }

  & svg {
    margin-top: 5px;
    margin-left: 5px;
  }
`

type VideoCardProps = {
  title: string
  video?: {
    streamingUrl: string
    muxPlaybackId: string
    muxAssetId: string
    mp4Url?: string
    mp4Med?: string
  }
  placeholderImage: {
    url: string
    responsiveImage: ResponsiveImageType
  }
  customHeight?: number
  textOverlay?: StructuredTextGraphQlResponse
}

export const VideoCard: React.FC<VideoCardProps> = ({ title, video, placeholderImage, customHeight, textOverlay }) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoStarted, setVideoStarted] = useState(false)

  const playButtonClickHandler = () => {
    if (!videoStarted) {
      videoRef.current?.play()
    }
  }

  const videoPauseHandler = () => {
    trackVideoEvent('cms', 'Pause', title, videoRef.current?.currentTime)
  }

  const videoCompleteHandler = () => {
    trackVideoEvent('cms', 'Complete', title, videoRef.current?.currentTime)
  }

  useEffect(() => {
    const videoElement = videoRef.current
    if (videoElement) {
      const handlePlay = () => {
        if (!videoStarted) {
          setVideoStarted(true)
          trackVideoEvent('cms', 'Play', title, videoRef.current?.currentTime)
        }
      }

      videoElement.addEventListener('playing', handlePlay)
      return () => {
        videoElement.removeEventListener('playing', handlePlay)
      }
    }
  }, [videoRef, videoStarted, title])

  const maxImageWidth = useMemo(() => {
    const responsiveHeight = placeholderImage?.responsiveImage?.height
    if (!customHeight || !responsiveHeight) {
      return undefined
    }
    const aspectRatio = placeholderImage.responsiveImage.width / responsiveHeight
    return Math.round(customHeight * aspectRatio)
  }, [customHeight, placeholderImage])

  return (
    <StyledVideoContainer
      $height={customHeight ? customHeight : undefined}
      onClick={video && playButtonClickHandler}
      $videoStarted={videoStarted}
      $hasVideo={!!video}
    >
      {textOverlay && !videoStarted && (
        <div>
          {video && (
            <AltStyledPlayButton onPress={playButtonClickHandler}>
              <DSIcon name="play" size={24} />
            </AltStyledPlayButton>
          )}
          <StyledTextOverlay>
            <DSFlex direction={'column'} justifyContent={'flex-end'} alignItems={'flex-end'}>
              <DSStyledStructuredText data={textOverlay} />
            </DSFlex>
          </StyledTextOverlay>
        </div>
      )}
      <StyledVideoWrap $maxWidth={maxImageWidth}>
        {/* To hide additional video play button on safari browser */}
        <img
          src={placeholderImage.url}
          hidden={videoStarted}
          alt={placeholderImage.responsiveImage.alt || ''}
          aria-hidden={!!video}
          height={customHeight ? customHeight : 550}
        />

        {video && (
          <video
            ref={videoRef}
            id="full"
            playsInline
            preload="auto"
            width="1280"
            height="720"
            controls
            onPause={videoPauseHandler}
            onEnded={videoCompleteHandler}
            poster={placeholderImage.url}
          >
            <source src={video.mp4Url}></source>
          </video>
        )}
        {video && !videoStarted && !textOverlay && (
          <StyledPlayButton>
            <StyledPlayIcon>
              <Image src="/assets/img/own-zoe/play.svg" alt="" width={23} height={26} />
            </StyledPlayIcon>
            <p>Press to play</p>
          </StyledPlayButton>
        )}
      </StyledVideoWrap>
    </StyledVideoContainer>
  )
}
