import styled from 'styled-components'
import React from 'react'
import { DSFlex } from '@/components/ds/Flex/Flex'
import { DSSpacer } from '@/components/ds/Spacer/Spacer'
import { Button } from 'react-aria-components'
import { CarouselTheme } from '@/components/pages/homepage/content/carousel/Carousel'
import { DSIcon } from '@zoe/ds-web'
import { useWindowSize } from '@/lib/hooks/useWindowSize'
import { BREAKPOINTS, convertPxToNumber, getBreakpointForWidth } from '@/components/ds/breakpoints'

const ControlButton = styled(Button)`
  display: block;
  height: var(--grid-48);
  width: var(--grid-48);
  padding: var(--grid-16);
  border-radius: 50%;
  border: 0;
  cursor: pointer;

  &:focus-visible {
    outline: 0;
  }

  &[data-hovered] {
    border: 1px solid var(--color-border-focused);
  }

  .neutral & {
    background-color: var(--color-surface-neutral);
  }

  .neutral-disabled & {
    background-color: var(--color-surface-neutral-disabled);
  }

  .teal & {
    background-color: var(--white-transparent);
  }
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 2px;
  background-color: var(--color-surface-neutral-disabled);
  display: flex;
`

const StyledProgress = styled.progress`
  width: 100%;
  height: 2px;
  border: none;
  background-color: var(--blue-400-15);
  border-radius: 5px;
  appearance: none; /* Hide default styles */

  /* Chrome/Safari/WebKit */

  &::-webkit-progress-bar {
    background-color: var(--blue-400-15);
    border-radius: 5px;
  }

  &::-webkit-progress-value {
    background-color: var(--color-surface-navy-bold);
    border-radius: 5px;
  }

  /* Firefox */

  &::-moz-progress-bar {
    background-color: var(--color-surface-navy-bold);
    border-radius: 5px;
  }

  /* For Internet Explorer */

  &::-ms-fill {
    background-color: var(--color-surface-navy-bold);
    border-radius: 5px;
  }

  .teal & {
    &::-webkit-progress-value {
      background-color: var(--color-surface-neutral);
      border-radius: 5px;
    }

    /* Firefox */

    &::-moz-progress-bar {
      background-color: var(--color-surface-neutral);
      border-radius: 5px;
    }

    /* For Internet Explorer */

    &::-ms-fill {
      background-color: var(--color-surface-neutral);
      border-radius: 5px;
    }
  }
`

interface ProgressBarProps {
  contentSize: number
  scrollProgress: number
  carouselRef: React.RefObject<HTMLDivElement>
  theme: CarouselTheme
  mobileTheme?: CarouselTheme
}

export const CarouselProgressControl = ({
  contentSize,
  scrollProgress,
  carouselRef,
  theme: desktopTheme,
  mobileTheme,
}: ProgressBarProps) => {
  const windowSize = useWindowSize()

  const useMobileTheme = (windowSize.width ?? 0) <= convertPxToNumber(BREAKPOINTS.m)
  const theme = useMobileTheme ? (mobileTheme ?? desktopTheme) : desktopTheme

  const getProgressPercentage = () => {
    const stepSize = 100 / (contentSize - 1)
    const adjustedProgressToStartFromFirstStep = Math.max(0, Math.min(scrollProgress, contentSize - 1))
    let progressPercentage = Math.round(
      stepSize + (adjustedProgressToStartFromFirstStep / (contentSize - 1)) * (100 - stepSize),
    )

    if (adjustedProgressToStartFromFirstStep >= contentSize - 1) {
      progressPercentage = 100
    }
    return progressPercentage
  }

  const iconColour = theme === 'teal' ? 'inverse' : 'primary'

  return (
    <div className={theme}>
      <ProgressBarWrapper>
        <StyledProgress max="100" value={getProgressPercentage()} />
      </ProgressBarWrapper>
      <DSSpacer size={24} direction="vertical" />
      <DSFlex direction="row" justifyContent="flex-start">
        <ControlButton
          onPress={() => carouselRef.current?.scrollBy({ left: -300, behavior: 'smooth' })}
          aria-label="left-control-carousel-button"
          isDisabled={scrollProgress <= 1}
        >
          <DSIcon name="chevron-left" size={16} color={iconColour} />
        </ControlButton>
        <DSSpacer size={8} direction="horizontal" />
        <ControlButton
          onPress={() => carouselRef.current?.scrollBy({ left: 300, behavior: 'smooth' })}
          aria-label="right-control-carousel-button"
          isDisabled={scrollProgress >= contentSize - 1}
        >
          <DSIcon name="chevron-right" size={16} color={iconColour} />
        </ControlButton>
      </DSFlex>
    </div>
  )
}
